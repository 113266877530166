import { FC } from 'react';

import { useTranslation } from 'next-i18next';
import { Box, Button, Typography } from '@mui/material';
import Link from 'next/link';
import IconImage from '@/assets/icons/404PageIcon.svg';

import { Container } from '@/components';

import styles from './styles.module.scss';

export const NotFound: FC = () => {
  const { t } = useTranslation('404');
  return (
    <Box className={styles.section}>
      <Container className={styles.container}>
        <IconImage className={styles.image} />
        <Typography variant={'h2'} className={styles.title}>
          {t('title')}
        </Typography>
        <Typography variant={'h3'} className={styles.description}>
          {t('description')}
        </Typography>
        <Link href={'/'}>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            className={styles.button}
          >
            {t('backButton')}
          </Button>
        </Link>
      </Container>
    </Box>
  );
};
