import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import type { GetStaticProps, NextPage } from 'next';
import { NotFound } from '@/features/404';

const NotFoundPage: NextPage = () => {
  return <NotFound />;
};

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {
      ...(await serverSideTranslations('en', ['common', 'metaData', '404'])),
    },
  };
};

export default NotFoundPage;
